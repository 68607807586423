import React from 'react';

const config = stateConfig;

const Testimonials = () => (
  <>
    <div className='d-flex align-items-center justify-content-center'>
      <div className='container'>
        <div className='col-12 mt-5'>
          <div className='px-2 px-lg-0'>
            <div>
              <p className='fw-bold mb-0'>{config.programName}</p>
              <h3 className='py-4'>Success Stories</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div dangerouslySetInnerHTML={{ __html: config.staticPages.testimonials.content }} />
  </ >
);

Testimonials.propTypes = {
};

export default Testimonials;
