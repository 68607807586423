import { createSlice } from '@reduxjs/toolkit';
import ls from 'local-storage';
import { toast } from 'react-toastify';

import { apiSlice } from '../apiSlice';
import { history } from '../../routers/AppRouter';
import axios from '../../utils/axios';


const initialState = {
  currentUser: {},
  isLoggedIn: false,
  isPWResetTokenValid: false,
  pre_screen: [],
  isPassedPrescreening: false,
  loading: false,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    /* AUTH */
    login: (auth, action) => {
      auth.currentUser = action.payload || {};
      auth.isLoggedIn = true;
      auth.pre_screen = [];
    },
    logout: (auth) => {
      auth.currentUser = {};
      auth.isLoggedIn = false;
      auth.pre_screen = [];
      auth.isPassedPrescreening = false;
    },
    register: (auth, action) => {
      auth.currentUser = action.payload;
      auth.isLoggedIn = true;
      auth.pre_screen = [];
    },
    resetPasswordValid: (auth, action) => {
      auth.isPWResetTokenValid = action.payload;
    },
    resetPassword: () => initialState,
    setPassPrescreening: (auth) => {
      auth.isPassedPrescreening = true;
    },
    savePrescreeningValues: (auth, action) => {
      auth.pre_screen = action.payload;
    },
    resetPrescreening: (auth) => {
      auth.isPassedPrescreening = false;
      auth.pre_screen = [];
    },
  },
});



export const {
  /* AUTH */
  login,
  logout,
  register,
  resetPasswordValid,
  resetPassword,
  setPassPrescreening,
  savePrescreeningValues,
  resetPrescreening,
} = slice.actions;

export default slice;

// const tags = {
//   sessions: 'sessions',
// };

const authApi = apiSlice
  // .enhanceEndpoints({ addTagTypes: Object.values(tags) })
  .injectEndpoints({
    endpoints: (build) => ({

      login: build.mutation({
        queryFn: async (payload, api) => {
          const { navigate, deepLink, setSubmitting, data } = payload;
          try {
            const response = await axios({
              url: `${baseURL}/login`,
              method: 'POST',
              data,
            });
            const {
              // refresh_tos,
              user,
            } = response.data;
            ls('user', user); // Add user data to Local Storage
            await api.dispatch(login(user));
            deepLink
              ? navigate(deepLink)
              : navigate('/application');
            return { data: response.data };
          } catch (error) {
            console.log('hit error');
            let isToast = true;
            let toastMessage = 'It looks like something went wrong. Please try again.';

            if (error.response && error.response.status === 409) {
              isToast = false;
              ls('email', payload.data.email);
              deepLink
                ? navigate('/validate/session', { state: { deepLink } })
                : navigate('/validate/session');
            }

            if (error.response && error.response.status === 423) {
              toastMessage = 'Too many failed login attempts. Please try again later.';
              navigate('/');
            }

            if (isToast) toast.error(toastMessage);
            console.error(error.response);
            setSubmitting();
            return { error: error.message };
          }
        },
        providesTags: [],
      }),



      register: build.mutation({
        queryFn: async (payload) => {
          try {
            const response = await axios({
              url: `${baseURL}/register`,
              method: 'POST',
              data: payload.data,
            });
            return { data: response.data };
          } catch (error) {
            let isToast = true;
            const toastMessage = 'It looks like something went wrong. Please try again.';

            if (error.response?.status === 409) {
              isToast = false;
              ls('email', payload.data.email);
              history.push('/validate/session');
            }

            if (isToast) toast.error(toastMessage);
            console.error(error.response);
            payload.setSubmitting();
            return { error: error.message };
          }
        },
      }),



      registerManual: build.mutation({
        queryFn: async (payload) => {
          try {
            const response = await axios({
              url: `${baseURL}/register-manual`,
              method: 'PUT',
              data: payload.data,
            });
            return { data: response.data };
          } catch (error) {
            payload.setSubmitting();
            console.error(error.response);
            let isToast = true;
            let toastMessage = 'It looks like something went wrong. Please try again.';

            if (error.response?.status === 409) {
              isToast = false;
              history.push('/login');
            }

            if (error.response?.status === 403) {
              toastMessage = 'Your invite has expired.';
              history.push('/resend-invitation');
            }

            if (isToast) toast.error(toastMessage);
            return { error: error.message };
          }
        },
      }),

      validateSession: build.mutation({
        queryFn: async (payload, api) => {
          const { data, deepLink, setSubmitting } = payload;
          try {
            const response = await axios({
              url: `${baseURL}/validate/session`,
              method: 'POST',
              data,
            });
            if (response.data.user) {
              const { user } = response.data;
              ls('user', user);
              ls.remove('email');
              await api.dispatch(login(user));
              // await api.dispatch(startGetProfile());
              deepLink
                ? history.push(deepLink)
                : history.push('/application');
            } else {
              toast.warn('Something went wrong, Resend a new code');
              setSubmitting();
            }
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            setSubmitting();
            toast.error('It looks like something went wrong. Please try again.');
            return { error: error.message };
          }
        },
      }),



      resendTwoFactor: build.mutation({
        queryFn: async () => { // ! Email saved LocalStorage due to 2FA
          const email = ls('email');
          try {
            const response = await axios({
              url: `${baseURL}/resend/mfa`,
              method: 'POST',
              data: { email },
            });
            toast.success('New Code Sent');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong. Please try again.');
            return { error: error.message };
          }
        },
      }),

      logout: build.mutation({
        queryFn: async (_, api) => {
          try {
            const response = await axios({
              url: `${baseURL}/logout`,
              method: 'GET',
            });
            ls.clear();
            api.dispatch(logout());
            history.push('/');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            ls.clear();
            api.dispatch(logout());
            history.push('/');
            toast.error('It looks like something went wrong.');
            return { error: error.message };
          }
        },
      }),

      sendForgotPasswordEmail: build.mutation({
        queryFn: async (payload) => {
          const { setSubmitting, cb, values } = payload;
          try {
            const response = await axios({
              url: `${baseURL}/reset-password-email`,
              method: 'POST',
              data: values,
            });
            setSubmitting();
            cb();
            return { data: response.data };
          } catch (error) {
            setSubmitting();
            console.error(error.response);
            toast.error('It looks like something went wrong. Please try again.');
            return { error: error.message };
          }
        },
      }),

      resendInviteManualEmail: build.mutation({
        queryFn: async ({ data, setSubmitting, cb }) => {
          try {
            const response = await axios({
              url: `${baseURL}/invite-manual-email`,
              method: 'POST',
              data,
            });
            setSubmitting();
            cb();
            return { data: response.data };
          } catch (error) {
            setSubmitting();
            console.error(error.response);
            toast.error('It looks like something went wrong. Please try again.');
            return { error: error.message };
          }
        },
      }),

      validateToken: build.query({
        queryFn: async (payload, api) => {
          try {
            const response = await axios({
              url: `${baseURL}/reset-password/${payload.token}`,
              method: 'GET',
            });
            api.dispatch(resetPasswordValid(response.data.isValid));
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong. Please try again.');
            return { error: error.message };
          }
        },
      }),

      resetPassword: build.mutation({
        queryFn: async (payload, api) => {
          const { data, setSubmitting } = payload;
          try {
            const response = await axios({
              url: `${baseURL}/reset-password`,
              method: 'POST',
              data,
            });
            api.dispatch(resetPassword());
            ls.clear();
            toast.success('Password updated. Please log back in.');
            history.push('/login');
            return { data: response.data };
          } catch (error) {
            setSubmitting();
            console.error(error);
            console.error(error.response); // ? Why are both consoled
            toast.error('It looks like something went wrong. Please try again.');
            return { error: error.message };
          }
        },
      }),


      subscribe: build.mutation({
        queryFn: async (payload) => {
          const { data, setSubmitting } = payload;
          try {
            const response = await axios({
              url: `${baseURL}/subscribe`,
              method: 'POST',
              data,
            });
            toast.success('Thank you. You have been subscribed to email updates.');
            history.push('/');
            return { data: response.data };
          } catch (error) {
            setSubmitting();
            console.error(error.response);
            toast.error('It looks like something went wrong. Please try again.');
            return { error: error.message };
          }
        },
      }),



      sendReportFraud: build.mutation({
        queryFn: async (payload) => {
          const { data, setSubmitting } = payload;
          try {
            const response = await axios({
              url: `${baseURL}/report_fraud`,
              method: 'POST',
              data,
            });
            toast.success('Thank you. Your fraud report has been submitted.');
            history.push('/');
            return { data: response.data };
          } catch (error) {
            setSubmitting();
            console.error(error.response);
            toast.error('It looks like something went wrong. Please try again.');
            return { error: error.message };
          }
        },
      }),

      sendTrusteeInquiry: build.mutation({
        queryFn: async (payload) => {
          const { data, setSubmitting } = payload;
          try {
            const response = await axios({
              url: `${baseURL}/trustee`,
              method: 'POST',
              data,
            });
            await axios.post(`${baseURL}/trustee`, payload);
            toast.success('Thank you. Your inquiry has been sent.');
            history.push('/');
            return { data: response.data };
          } catch (error) {
            setSubmitting();
            console.error(error.response);
            toast.error('It looks like something went wrong. Please try again.');
            return { error: error.message };
          }
        },
      }),

      addPrescreeningResponse: build.mutation({
        queryFn: async (payload, api) => {
          const { answer, question } = payload;
          try {
            const prescreen = api.getState().auth.pre_screen;
            const value = { question, answer };
            const return_values = [...prescreen, value];
            api.dispatch(savePrescreeningValues(return_values)); // Add prescreen data to redux store
            return { data: return_values };
          } catch (error) {
            console.error(error.response);
            return { error: 'Prescreen Fail' };
          }
        },
      }),

      resetPrescreening: build.mutation({
        queryFn: async (_, api) => {
          try {
            api.dispatch(resetPrescreening());
            ls.remove('prescreen');
            return { data: 'Prescreen Reset' };
          } catch (error) {
            console.error(error.response);
            return { error: 'Prescreen Fail' };
          }
        },
      }),

      setPassPrescreening: build.mutation({
        queryFn: async (_, api) => {
          try {
            api.dispatch(setPassPrescreening());
            return { data: 'All prescreen questions passed' };
          } catch (error) {
            console.error(error.response);
            return { error: 'Prescreen Fail' };
          }
        },
      }),

      sendPrescreenFailure: build.mutation({
        queryFn: async (payload) => {
          try {
            const response = await axios({
              url: `${baseURL}/pre_screen_failures`,
              method: 'POST',
              data: payload,
            });
            return { data: response.data };
          } catch (error) {
            return { error: error.message };
          }
        },
      }),

    }),
  });



export const {
  useLoginMutation,
  useRegisterMutation,
  useRegisterManualMutation,
  useValidateSessionMutation,
  useResendTwoFactorMutation,
  useLogoutMutation,
  useSendForgotPasswordEmailMutation,
  useResendInviteManualEmailMutation,
  useLazyValidateTokenQuery,
  useResetPasswordMutation,
  useSetPassPrescreeningMutation,
  useSubscribeMutation,
  useSendReportFraudMutation,
  useSendTrusteeInquiryMutation,
  useAddPrescreeningResponseMutation,
  useResetPrescreeningMutation,
  useSendPrescreenFailureMutation,
} = authApi;
