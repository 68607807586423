import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import axios from '../../utils/axios';


const AppWrapper = ({ children }) => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  let intervalId;

  const hb = async () => {
    await axios.get(`${baseURL}/hb`);
  };


  const startInterval = () => {
    intervalId = setInterval(hb, hbInterval);
  };


  const stopInterval = () => {
    clearInterval(intervalId);
    intervalId = null;
  };


  useEffect(() => {
    if (hbEnabled) {
      if (isLoggedIn && !intervalId) {
        startInterval();
        axios.get(`${baseURL}/hb`)
          .then((response) => console.log(response.data))
          .catch((error) => console.log(error));
      }
      return stopInterval;
    }
  }, [isLoggedIn]);

  return (
    <div className='app-wrapper' id='app-wrapper'>
      {children}
    </div>
  );
};

AppWrapper.propTypes = {
  contentContainer: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  icon: PropTypes.element,
  size: PropTypes.string,
  title: PropTypes.string,
};

export default AppWrapper;
