import React from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useLocation } from 'react-router';

const PassEligibleRoute = ({ redirectPath = '/', children }) => {
  const location = useLocation();

  const isPassedPrescreening = useSelector((state) => state.auth.isPassedPrescreening);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  if (isLoggedIn) return <Navigate to={'/application'} replace state={{ from: location }}/>;
  if (!isPassedPrescreening) return <Navigate to={redirectPath} replace state={{ from: location }}/>;

  return children;
};

PassEligibleRoute.propTypes = {
  redirectPath: PropTypes.string,
  children: PropTypes.element,
};

export default PassEligibleRoute;
