import React from 'react';
import { createRoot } from 'react-dom/client';

import { ScrollToTopButton } from 'bumblebee-ui';
import ls from 'local-storage';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components';

import { login } from './redux/reducers/auth';
import createStore from './redux/store';
import AppRouter from './routers/AppRouter';
import './styles/styles.scss';
import 'focus-visible';
import { FocusVisible } from './theme/FocusVisible';
import { GlobalStyles } from './theme/GlobalStyles';

export const store = createStore();

if (ls('user')) {
  const user = ls('user');
  console.log('User: ', user);
  store.dispatch(login(user));
}

if (ls('authorization')) {
  ls.remove('authorization');
}

const jsx = (
  <Provider store={store}>
    <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover />

      <FocusVisible className="js-focus-visible focus-visible">
        <ThemeProvider theme={stateConfig.style}>
          <GlobalStyles />
          <AppRouter />
        </ThemeProvider>
      </FocusVisible>

      <ScrollToTopButton handleClick={() => {
        window.scrollTo(0, 0);
      }} />
    </DndProvider>
  </Provider>
);

if (window.Cypress && environment !== 'production') {
  window.store = store;
  window.config = stateConfig;
}

const container = document.getElementById('app');
const root = createRoot(container);
root.render(jsx);
