import React from 'react';
// import PropTypes from 'prop-types';

import { PageWrapper, ContentWrapper } from 'bumblebee-ui';

const config = stateConfig;

const Resources = () => {
  let headerText = 'Helpful Links';
  if (config.state === 'Mississippi') {
    headerText = 'HAF Status Dashboard';
  }
  if (config.state === 'Georgia') {
    headerText = 'Georgia Mortgage Assistance Resources';
  }
  if (config.state === 'Nebraska') {
    headerText = 'NHAF Dashboard';
  }
  if (config.state === 'Missouri') {
    headerText = 'Resources';
  }
  if (config.state === 'New Jersey') {
    headerText = 'Housing Counselors';
  }

  return (
    <PageWrapper>
      <ContentWrapper
        headerDesc={<p className='fw-bold mb-0'>{config.programName}</p>}
        header={headerText}
      >
        <div dangerouslySetInnerHTML={{ __html: config.staticPages.resources.content }} />
      </ContentWrapper>
    </PageWrapper>
  );
};

Resources.propTypes = {
};

export default Resources;
