import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';

const GoogleTranslate = () => {
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement({ pageLanguage: 'en', layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE, autoDisplay: false }, 'google_translate_element');
  };

  useEffect(() => {
    const addScript = document.createElement('script');
    addScript.setAttribute('nonce', 'nonceReplace');
    addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  // Prevent default behavior
  useEffect(() => {
    const preventDefaultBehavior = (e) => {
      e.preventDefault();
    };

    const translateElement = document.getElementById('google_translate_element');
    if (translateElement) {
      translateElement.addEventListener('click', preventDefaultBehavior);
    }
  }, []);

  return (
    <div className='google-translate'>
      <div id='google_translate_element'></div>
    </div>
  );
};

GoogleTranslate.propTypes = {
};

export default GoogleTranslate;
