import React from 'react';
import PropTypes from 'prop-types';

import { useLocation } from 'react-router';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ContentLoadingWrapper } from 'bumblebee-ui';

import { useGetLandingPageQuery } from '../../redux/reducers/config';

const config = stateConfig;

const PreScreenRoute = ({ redirectPath = '/', children }) => {
  const location = useLocation();
  const { data: landingPage = {}, isFetching, isError } = useGetLandingPageQuery();

  return <ContentLoadingWrapper
    loading={isFetching}
    error={isError}
    content={() => {
      if (!landingPage?.is_accepting_applications) {
        toast.success(`${config.reportFraudPage.programName} is not accepting applications at this time`);
        return <Navigate to={redirectPath} replace state={{ from: location }} />;
      }
      return children;
    }}
  />;
};

PreScreenRoute.propTypes = {
  redirectPath: PropTypes.string,
  children: PropTypes.element,
};

export default PreScreenRoute;
