import { createGlobalStyle } from 'styled-components';
import { hexToRGB } from '../utils/helpers';

export const GlobalStyles = createGlobalStyle`
  /* badge color */
  .badge-primary {
    background-color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};
  }

  .badge-secondary {
    background-color: ${({ theme }) => theme.colors.secondary};
    border-color: ${({ theme }) => theme.colors.secondary};
  }

  .badge-info {
    background-color: ${({ theme }) => theme.colors.info};
    border-color: ${({ theme }) => theme.colors.info};
  }

  .badge-warning {
    background-color: ${({ theme }) => theme.colors.warning};
    border-color: ${({ theme }) => theme.colors.warning};
  }

  .badge-danger {
    background-color: ${({ theme }) => theme.colors.danger};
    border-color: ${({ theme }) => theme.colors.danger};
  }

  .badge-success {
    background-color: ${({ theme }) => theme.colors.success};
    border-color: ${({ theme }) => theme.colors.success};
  }

  /* button color */
  .btn.btn-primary,
  .btn-primary {
    background-color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};
    &:hover, &:active, &:not(:disabled):not(.disabled):active {
      background-color: ${({ theme }) => theme.colors.hover.primary};
      border-color: ${({ theme }) => theme.colors.hover.primary};
    }
    &:disabled {
      background-color: ${({ theme }) => theme.colors.hover.primary};
      border-color: ${({ theme }) => theme.colors.hover.primary};
    }
    &:focus {
      border-color: ${({ theme }) => theme.colors.primary};
      box-shadow: 0 0 0.25rem 0.05rem rgba(${({ theme }) => hexToRGB(theme.colors.primary)}, 0.5) !important;
    }
  }

  .btn.btn-outline-primary,
  .btn-outline-primary {
    background-color: white;
    color: ${({ theme }) => theme.colors.primary};
    &:hover, &:active, &:not(:disabled):not(.disabled):active {
      background-color: white;
      color: ${({ theme }) => theme.colors.hover.primary};
    }
    &:disabled {
      background-color: white;
      color: ${({ theme }) => theme.colors.hover.primary};
    }
    &:focus {
      border-color: ${({ theme }) => theme.colors.primary};
      box-shadow: 0 0 0.25rem 0.05rem rgba(${({ theme }) => hexToRGB(theme.colors.primary)}, 0.5) !important;
    }
  }

  .btn.btn-ghost-primary,
  .btn-ghost-primary {
    color: ${({ theme }) => theme.colors.primary};
    &:hover, &:active, &:not(:disabled):not(.disabled):active {
      color: ${({ theme }) => theme.colors.hover.primary};
    }
    &:disabled {
      color: ${({ theme }) => theme.colors.hover.primary};
    }
    &:focus {
      color: ${({ theme }) => theme.colors.hover.primary};
      border-color: ${({ theme }) => theme.colors.primary};
      box-shadow: 0 0 0.25rem 0.05rem rgba(${({ theme }) => hexToRGB(theme.colors.primary)}, 0.5) !important;
    }
  }

  .btn.btn-secondary,
  .btn-secondary {
    background-color: ${({ theme }) => theme.colors.secondary};
    border-color: ${({ theme }) => theme.colors.secondary};
    &:hover, &:active, &:not(:disabled):not(.disabled):active {
      background-color: ${({ theme }) => theme.colors.hover.secondary};
      border-color: ${({ theme }) => theme.colors.hover.secondary};
    }
    &:disabled {
      background-color: ${({ theme }) => theme.colors.hover.secondary};
      border-color: ${({ theme }) => theme.colors.hover.secondary};
    }
  }

  .btn.btn-outline-secondary,
  .btn-outline-secondary {
    background-color: white;
    color: ${({ theme }) => theme.colors.secondary};
    &:hover, &:active, &:not(:disabled):not(.disabled):active {
      background-color: white;
      color: ${({ theme }) => theme.colors.hover.secondary};
    }
    &:disabled {
      background-color: white;
      color: ${({ theme }) => theme.colors.hover.secondary};
    }
  }

  .btn.btn-ghost-secondary,
  .btn-ghost-secondary {
    color: ${({ theme }) => theme.colors.secondary};
    &:hover, &:active, &:not(:disabled):not(.disabled):active {
      color: ${({ theme }) => theme.colors.hover.secondary};
    }
    &:disabled {
      color: ${({ theme }) => theme.colors.hover.secondary};
    }
    &:focus {
      color: ${({ theme }) => theme.colors.hover.secondary};
      border-color: ${({ theme }) => theme.colors.secondary};
    }
  }

  .btn.btn-info,
  .btn-info {
    background-color: ${({ theme }) => theme.colors.info};
    border-color: ${({ theme }) => theme.colors.info};
    &:hover, &:active, &:not(:disabled):not(.disabled):active {
      background-color: ${({ theme }) => theme.colors.hover.info};
      border-color: ${({ theme }) => theme.colors.hover.info};
    }
    &:disabled {
      background-color: ${({ theme }) => theme.colors.hover.info};
      border-color: ${({ theme }) => theme.colors.hover.info};
    }
  }

  .btn.btn-outline-info,
  .btn-outline-info {
    background-color: white;
    color: ${({ theme }) => theme.colors.info};
    &:hover, &:active, &:not(:disabled):not(.disabled):active {
      background-color: white;
      color: ${({ theme }) => theme.colors.hover.info};
    }
    &:disabled {
      background-color: white;
      color: ${({ theme }) => theme.colors.hover.info};
    }
  }

  .btn.btn-ghost-info,
  .btn-ghost-info {
    color: ${({ theme }) => theme.colors.info};
    &:hover, &:active, &:not(:disabled):not(.disabled):active {
      color: ${({ theme }) => theme.colors.hover.info};
    }
    &:disabled {
      color: ${({ theme }) => theme.colors.hover.info};
    }
    &:focus {
      color: ${({ theme }) => theme.colors.hover.info};
      border-color: ${({ theme }) => theme.colors.info};
    }
  }

  .btn.btn-warning,
  .btn-warning {
    background-color: ${({ theme }) => theme.colors.warning};
    border-color: ${({ theme }) => theme.colors.warning};
    &:hover, &:active, &:not(:disabled):not(.disabled):active {
      background-color: ${({ theme }) => theme.colors.hover.warning};
      border-color: ${({ theme }) => theme.colors.hover.warning};
    }
    &:disabled {
      background-color: ${({ theme }) => theme.colors.hover.warning};
      border-color: ${({ theme }) => theme.colors.hover.warning};
    }
  }

  .btn.btn-outline-warning,
  .btn-outline-warning {
    background-color: white;
    color: ${({ theme }) => theme.colors.warning};
    &:hover, &:active, &:not(:disabled):not(.disabled):active {
      background-color: white;
      color: ${({ theme }) => theme.colors.hover.warning};
    }
    &:disabled {
      background-color: white;
      color: ${({ theme }) => theme.colors.hover.warning};
    }
  }

  .btn.btn-ghost-warning,
  .btn-ghost-warning {
    color: ${({ theme }) => theme.colors.warning};
    &:hover, &:active, &:not(:disabled):not(.disabled):active {
      color: ${({ theme }) => theme.colors.hover.warning};
    }
    &:disabled {
      color: ${({ theme }) => theme.colors.hover.warning};
    }
    &:focus {
      color: ${({ theme }) => theme.colors.hover.warning};
      border-color: ${({ theme }) => theme.colors.warning};
    }
  }

  .btn.btn-warning,
  .btn-warning {
    background-color: ${({ theme }) => theme.colors.warning};
    border-color: ${({ theme }) => theme.colors.warning};
    &:hover, &:active, &:not(:disabled):not(.disabled):active {
      background-color: ${({ theme }) => theme.colors.hover.warning};
      border-color: ${({ theme }) => theme.colors.hover.warning};
    }
    &:disabled {
      background-color: ${({ theme }) => theme.colors.hover.warning};
      border-color: ${({ theme }) => theme.colors.hover.warning};
    }
  }

  .btn.btn-outline-danger,
  .btn-outline-danger {
    background-color: white;
    color: ${({ theme }) => theme.colors.danger};
    &:hover, &:active, &:not(:disabled):not(.disabled):active {
      background-color: white;
      color: ${({ theme }) => theme.colors.hover.danger};
    }
    &:disabled {
      background-color: white;
      color: ${({ theme }) => theme.colors.hover.danger};
    }
  }

  .btn.btn-ghost-danger,
  .btn-ghost-danger {
    color: ${({ theme }) => theme.colors.danger};
    &:hover, &:active, &:not(:disabled):not(.disabled):active {
      color: ${({ theme }) => theme.colors.hover.danger};
    }
    &:disabled {
      color: ${({ theme }) => theme.colors.hover.danger};
    }
    &:focus {
      color: ${({ theme }) => theme.colors.hover.danger};
      border-color: ${({ theme }) => theme.colors.danger};
    }
  }

  .btn.btn-success,
  .btn-success {
    background-color: ${({ theme }) => theme.colors.success};
    border-color: ${({ theme }) => theme.colors.success};
    &:hover, &:active, &:not(:disabled):not(.disabled):active {
      background-color: ${({ theme }) => theme.colors.hover.success};
      border-color: ${({ theme }) => theme.colors.hover.success};
    }
    &:disabled {
      background-color: ${({ theme }) => theme.colors.hover.success};
      border-color: ${({ theme }) => theme.colors.hover.success};
    }
  }

  .btn.btn-outline-success,
  .btn-outline-success {
    background-color: white;
    color: ${({ theme }) => theme.colors.success};
    &:hover, &:active, &:not(:disabled):not(.disabled):active {
      background-color: white;
      color: ${({ theme }) => theme.colors.hover.success};
    }
    &:disabled {
      background-color: white;
      color: ${({ theme }) => theme.colors.hover.success};
    }
  }

  .btn.btn-ghost-success,
  .btn-ghost-success {
    color: ${({ theme }) => theme.colors.success};
    &:hover, &:active, &:not(:disabled):not(.disabled):active {
      color: ${({ theme }) => theme.colors.hover.success};
    }
    &:disabled {
      color: ${({ theme }) => theme.colors.hover.success};
    }
    &:focus {
      color: ${({ theme }) => theme.colors.hover.success};
      border-color: ${({ theme }) => theme.colors.success};
    }
  }

  .btn.btn-white,
  .btn-white {
    color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};
    &:hover, &:active, &:not(:disabled):not(.disabled):active {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  .btn.btn-link,
  .btn-link {
    color: ${({ theme }) => theme.colors.primary};
    &:hover {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.hover.primary};
    }
  }

  .btn.btn-filter,
  .btn-filter {
    color: ${({ theme }) => theme.colors.primary} !important;
    &:hover {
      background-color: ${({ theme }) => theme.colors.grays.v100} !important;
    }

    &.active {
      background-color: ${({ theme }) => theme.colors.secondary} !important;
      &:hover {
        background-color: ${({ theme }) => theme.colors.hover.secondary} !important;
      }
    }

    &.full {
      background-color: ${({ theme }) => theme.colors.primary} !important;
      color: white !important;
      &:hover {
        background-color: ${({ theme }) => theme.colors.hover.primary} !important;
      }
    }
  }

  .btn.btn-scroll-to-top,
  .btn-scroll-to-top {
    background-color: ${({ theme }) => theme.colors.primary} !important;
  }

  .btn.btn-add-filter,
  .btn-add-filter {
    color: ${({ theme }) => theme.colors.primary} !important;
    &:hover {
      color: ${({ theme }) => theme.colors.hover.primary} !important;
    }
  }

  /* Text color */
  .text-primary { color: ${({ theme }) => theme.colors.primary} !important; }
  .text-secondary { color: ${({ theme }) => theme.colors.secondary} !important; }
  .text-info { color: ${({ theme }) => theme.colors.info} !important; }
  .text-warning { color: ${({ theme }) => theme.colors.warning} !important; }
  .text-danger { color: ${({ theme }) => theme.colors.danger} !important; }
  .text-success { color: ${({ theme }) => theme.colors.success} !important; }
  .text-muted { color: ${({ theme }) => theme.colors.grays.v600} !important; }
  .text-muted-light { color: ${({ theme }) => theme.colors.grays.v400} !important; }
  .text-link {
    color: ${({ theme }) => theme.colors.primary};
    &:hover {
      color: ${({ theme }) => theme.colors.hover.primary};
      text-decoration: underline;
      cursor: pointer;
    }
  }

  /* Landing Page banner */
  .landing-page-banner {
    background: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
  }

  /* Landing Page alert block */
  .alert-block {
    background: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    p {
      margin-bottom: 0;
    };
    a {
      color: ${({ theme }) => theme.colors.white} !important;
    };
  }

  /* Pagination */
  .pagination {
    .page-item {
      &.active, &:hover, &:active {
        .page-link {
          background: ${({ theme }) => theme.colors.primary};
          border-color: ${({ theme }) => theme.colors.primary};
          color: #FFFFFF !important;
          z-index: 0;
        }
      }
    }
  }

  /* Footer */
  .footer, .footer > div > div {
    background-color: ${({ theme }) => theme.colors.footerBG};
  }

  /* Toast */
  .Toastify__toast--error {
    background: ${({ theme }) => theme.colors.danger} !important;
  }

  .Toastify__toast--success {
    background: ${({ theme }) => theme.colors.success} !important;
  }

  .Toastify__toast--info {
    background: ${({ theme }) => theme.colors.info} !important;
  }

  .Toastify__toast--warn {
    background: ${({ theme }) => theme.colors.warning} !important;
  }

  /* Nav */
  .navbar {
    background-color: white;
    .navbar-menu-wrapper {
      color: ${({ theme }) => theme.colors.navText};
      background-color: ${({ theme }) => theme.colors.navBG};
      .navbar-nav {
        .nav-item {
          color: ${({ theme }) => theme.colors.navText};
          &.active {
            color: ${({ theme }) => theme.colors.primary};
            background-color: ${({ theme }) => (theme.colors.navBG !== '#FFFFFF' || theme.colors.navBG !== '#ffffff') && '#FFFFFF'};
          }
          &:hover {
            color: ${({ theme }) => theme.colors.primary};
          }
        }
      }
    }
  }

  .nav-tabs {
    .nav-item {
      a.nav-link {
        color: ${({ theme }) => theme.colors.grays.v600} !important;
      }
      .nav-link.active {
        border-bottom: 2px solid ${({ theme }) => theme.colors.primary} !important;
        color: ${({ theme }) => theme.colors.primary} !important;
      }
    }
  }

  /* modal color
  .modal .modal-dialog .modal-content .modal-header {
    background: ${({ theme }) => theme.colors.primary};
  }
  */

  /* a tag link style */
  a {
    color: ${({ theme }) => theme.colors.primary} !important;
  }

  // Custom checkbox
  input[type='checkbox'] {
    &:checked {
      + #check {
        background: ${({ theme }) => `${theme.colors.primary} -19px top no-repeat;`};
      }
    }
  }

  /* Border Colors */
  .border-primary { border-color: ${({ theme }) => theme.colors.primary} !important; }
  .border-secondary { border-color: ${({ theme }) => theme.colors.secondary} !important; }
  .border-info { border-color: ${({ theme }) => theme.colors.info} !important; }
  .border-warning { border-color: ${({ theme }) => theme.colors.warning} !important; }
  .border-danger { border-color: ${({ theme }) => theme.colors.danger} !important; }
  .border-success { border-color: ${({ theme }) => theme.colors.success} !important; }

  /* Subtle Background Colors */
  .bg-primary-subtle { background-color: rgba(${({ theme }) => hexToRGB(theme.colors.primary)}, 0.2) !important; }
  .bg-secondary-subtle { background-color: rgba(${({ theme }) => hexToRGB(theme.colors.primary)}, 0.2) !important; }
  .bg-info-subtle { background-color: rgba(${({ theme }) => hexToRGB(theme.colors.primary)}, 0.2) !important; }
  .bg-warning-subtle { background-color: rgba(${({ theme }) => hexToRGB(theme.colors.primary)}, 0.2) !important; }
  .bg-danger-subtle { background-color: rgba(${({ theme }) => hexToRGB(theme.colors.primary)}, 0.2) !important; }
  .bg-success-subtle { background-color: rgba(${({ theme }) => hexToRGB(theme.colors.primary)}, 0.2) !important; }

  /* Text reset to state color */
  .text-reset:hover,
  .text-reset:active,
  .text-reset:focus {
    color: ${({ theme }) => theme.colors.primary} !important;
  }
`;
