import { apiSlice } from '../apiSlice';
import axios from '../../utils/axios';



export const tags = {};

const configApi = apiSlice
  .enhanceEndpoints({ addTagTypes: Object.values(tags) })
  .injectEndpoints({
    endpoints: (build) => ({

      getHowdYouHear: build.query({
        queryFn: async () => {
          try {
            const response = await axios({
              url: `${baseURL}/app-config/howd-you-hear`,
              method: 'GET',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        providesTags: [],
      }),



      getLandingPage: build.query({
        queryFn: async () => {
          try {
            const response = await axios({
              url: `${baseURL}/app-config/landing-page`,
              method: 'GET',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        providesTags: [],
      }),



      getConfigAFR: build.query({
        queryFn: async () => {
          try {
            const response = await axios({
              url: `${baseURL}/app-config/additional-funding-requests`,
              method: 'GET',
            });
            return { data: response.data?.[0] || {} };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        providesTags: [],
      }),



      getConfigCounties: build.query({
        queryFn: async () => {
          try {
            const response = await axios({
              url: `${baseURL}/app-config/counties`,
              method: 'GET',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        providesTags: [],
      }),



      getConfigEmploymentStatuses: build.query({
        queryFn: async () => {
          try {
            const response = await axios({
              url: `${baseURL}/app-config/employment-status`,
              method: 'GET',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        providesTags: [],
      }),



      getConfigESign: build.query({
        queryFn: async () => {
          try {
            const response = await axios({
              url: `${baseURL}/app-config/e-sign`,
              method: 'GET',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        providesTags: [],
      }),



      getConfigExpenseCategories: build.query({
        queryFn: async () => {
          try {
            const response = await axios({
              url: `${baseURL}/app-config/expense-categories`,
              method: 'GET',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        providesTags: [],
      }),



      getConfigHowdYouHear: build.query({
        queryFn: async () => {
          try {
            const response = await axios({
              url: `${baseURL}/app-config/howd-you-hear`,
              method: 'GET',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        providesTags: [],
      }),



      getConfigIncomeCategories: build.query({
        queryFn: async () => {
          try {
            const response = await axios({
              url: `${baseURL}/app-config/income-categories`,
              method: 'GET',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        providesTags: [],
      }),



      getConfigMaritalStatuses: build.query({
        queryFn: async () => {
          try {
            const response = await axios({
              url: `${baseURL}/app-config/marital-status`,
              method: 'GET',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        providesTags: [],
      }),



      getConfigMortgageTypes: build.query({
        queryFn: async () => {
          try {
            const response = await axios({
              url: `${baseURL}/app-config/mortgage-types`,
              method: 'GET',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        providesTags: [],
      }),



      getConfigProcessorStatuses: build.query({
        queryFn: async () => {
          try {
            const response = await axios({
              url: `${baseURL}/app-config/processor-status`,
              method: 'GET',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        providesTags: [],
      }),



      getConfigPrograms: build.query({
        queryFn: async () => {
          try {
            const response = await axios({
              url: `${baseURL}/app-config/programs`,
              method: 'GET',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        providesTags: [],
      }),



      getConfigPropertyTypes: build.query({
        queryFn: async () => {
          try {
            const response = await axios({
              url: `${baseURL}/app-config/property-types`,
              method: 'GET',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        providesTags: [],
      }),



      getConfigRecertification: build.query({
        queryFn: async () => {
          try {
            const response = await axios({
              url: `${baseURL}/app-config/recertification`,
              method: 'GET',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        providesTags: [],
      }),



      getConfigVendors: build.query({
        queryFn: async () => {
          try {
            const response = await axios({
              url: `${baseURL}/app-config/vendors`,
              method: 'GET',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        providesTags: [],
      }),



      getConfigZipCodes: build.query({
        queryFn: async () => {
          try {
            const response = await axios({
              url: `${baseURL}/app-config/zip-codes`,
              method: 'GET',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        providesTags: [],
      }),
    }),
  });

export const {
  useGetHowdYouHearQuery,
  useGetLandingPageQuery,
  useGetConfigAFRQuery,
  useGetConfigCountiesQuery,
  useGetConfigESignQuery,
  useGetConfigEmploymentStatusesQuery,
  useGetConfigExpenseCategoriesQuery,
  useGetConfigHowdYouHearQuery,
  useGetConfigIncomeCategoriesQuery,
  useGetConfigMaritalStatusesQuery,
  useGetConfigMortgageTypesQuery,
  useGetConfigProcessorStatusesQuery,
  useGetConfigProgramsQuery,
  useGetConfigPropertyTypesQuery,
  useGetConfigRecertificationQuery,
  useGetConfigVendorsQuery,
  useGetConfigZipCodesQuery,
} = configApi;
