import { toast } from 'react-toastify';

import { apiSlice } from '../apiSlice';
import axios from '../../utils/axios';
import { history } from '../../routers/AppRouter';


export const tags = {
  profile: 'profile',
  afrProfile: 'afrProfile',
  afrs: 'afrs',
  coapplicants: 'coapplicants',
  householdMembers: 'householdMembers',
  loans: 'loans',
  assistanceRequests: 'assistanceRequests',
  financials: 'financials',
  hardship: 'hardship',
  signatures: 'signatures',
  status: 'status',
};

const applicationApi = apiSlice
  .enhanceEndpoints({ addTagTypes: Object.values(tags) })
  .injectEndpoints({
    endpoints: (build) => ({

      // Get Applicant Profile
      getProfile: build.query({
        queryFn: async () => {
          try {
            const response = await axios({
              url: `${baseURL}/user`,
              method: 'GET',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        providesTags: [tags.profile],
      }),



      // Get AFR Profile
      getAFRProfile: build.query({
        queryFn: async ({ afr_uuid }) => {
          try {
            const response = await axios({
              url: `${baseURL}/afr/user/${afr_uuid}`,
              method: 'GET',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        providesTags: [tags.afrProfile],
      }),



      updateProfile: build.mutation({
        queryFn: async ({ data, setSubmitting }) => {
          try {
            const response = await axios({
              url: `${baseURL}/user`,
              method: 'PUT',
              data,
            });
            toast.success('Profile updated!');
            history.push('/application');
            return { data: response.data };
          } catch (error) {
            setSubmitting();
            console.error(error.response);
            toast.error('It looks like something went wrong. Please try again.');
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.profile]),
      }),



      getVerifiedAddresses: build.query({
        queryFn: async ({ enteredAddress }) => {
          const addressString = `${enteredAddress.property_address_one}${enteredAddress.property_address_two ? ` ${enteredAddress.property_address_two}` : ''}, ${enteredAddress.property_city}, ${enteredAddress.property_state} ${enteredAddress.property_zip}`;
          try {
            const response = await axios({
              url: `${baseURL}/validate-address`,
              method: 'POST',
              data: { address: addressString },
            });
            const addresses = {
              enteredAddress,
              verifiedAddresses: response.data,
            };
            return { data: addresses };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong. Please try again.');
            return { error };
          }
        },
        providesTags: [],
      }),



      // Get LWC Homestead/Occupancy
      getLWCHomesteadOcc: build.query({
        queryFn: async ({ data }) => {
          try {
            const response = await axios({
              url: `${baseURL}/lwc-housing/address`,
              method: 'POST',
              data,
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        providesTags: [],
      }),



      // Get All AFRS
      getAllAFRs: build.query({
        queryFn: async () => {
          try {
            const response = await axios({
              url: `${baseURL}/afr/user`,
              method: 'GET',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        providesTags: [tags.afrs],
      }),



      // startUpdateAFRProfile
      updateAFRProfile: build.mutation({
        queryFn: async (payload) => {
          const { afr_uuid, data, setSubmitting } = payload;
          try {
            const response = await axios({
              url: `${baseURL}/afr/user/${afr_uuid}`,
              method: 'PUT',
              data,
            });
            toast.success('Profile updated!');
            history.push(`/application/afr/${afr_uuid}`);
            return { data: response.data };
          } catch (error) {
            setSubmitting();
            console.error(error.response);
            toast.error('It looks like something went wrong. Please try again.');
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.afrProfile]),
      }),



      startAdditionalFundsRequest: build.mutation({
        queryFn: async ({ toggle }) => {
          try {
            const response = await axios({
              url: `${baseURL}/afr/request`,
              method: 'POST',
            });
            history.push(`/application/afr/${response.data}`);
            toast.success('Additional Funding Request created!');
            toggle();
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong. Please try again.');
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.afrs, tags.afrProfile, tags.profile, tags.status]),
      }),



      getCoapplicants: build.query({
        queryFn: async ({ isAFR, afr_uuid }) => {
          let url = `${baseURL}/coapplicants`;
          if (isAFR) url = `${baseURL}/afr/coapplicants/afr-id/${afr_uuid}`;
          try {
            const response = await axios({
              url,
              method: 'GET',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        providesTags: [tags.coapplicants],
      }),



      getCoapplicant: build.query({
        queryFn: async ({ isAFR, coapplicant_uuid }) => {
          let url = `${baseURL}/coapplicants/${coapplicant_uuid}`;
          if (isAFR) url = `${baseURL}/afr/coapplicants/${coapplicant_uuid}`;
          try {
            const response = await axios({
              url,
              method: 'GET',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        providesTags: [tags.coapplicants],
      }),



      // startAddCoapplicant
      addCoapplicant: build.mutation({
        queryFn: async ({ data, setSubmitting }) => {
          try {
            const response = await axios({
              url: `${baseURL}/coapplicants`,
              method: 'POST',
              data,
            });
            toast.success('Created!');
            history.push('/application');
            return { data: response.data };
          } catch (error) {
            setSubmitting();
            console.error(error.response);
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.coapplicants, tags.profile]),
      }),



      // Update Coapplicant
      updateCoapplicant: build.mutation({
        queryFn: async ({ coapplicant_uuid, data, setSubmitting }) => {
          try {
            const response = await axios({
              url: `${baseURL}/coapplicants/${coapplicant_uuid}`,
              method: 'PUT',
              data,
            });
            toast.success('Updated!');
            history.push('/application');
            return { data: response.data };
          } catch (error) {
            setSubmitting();
            console.error(error.response);
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.coapplicants]),
      }),



      // Delete Coapplicant
      deleteCoapplicant: build.mutation({
        queryFn: async ({ coapplicant_uuid }) => {
          try {
            const response = await axios({
              url: `${baseURL}/coapplicants/${coapplicant_uuid}`,
              method: 'DELETE',
            });
            toast.success('Deleted!');
            history.push('/application');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.coapplicants]),
      }),



      // Add AFR Coapplicant
      addAFRCoapplicant: build.mutation({
        queryFn: async ({ afr_uuid, data, setSubmitting }) => {
          try {
            const response = await axios({
              url: `${baseURL}/afr/coapplicants/${afr_uuid}`,
              method: 'POST',
              data,
            });
            toast.success('Created!');
            history.push(`/application/afr/${afr_uuid}`);
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            setSubmitting();
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.coapplicants, tags.afrProfile]),
      }),



      // Update AFR Coapplicant
      updateAFRCoapplicant: build.mutation({
        queryFn: async ({ afr_uuid, coapplicant_uuid, data, setSubmitting }) => {
          try {
            const response = await axios({
              url: `${baseURL}/afr/coapplicants/${coapplicant_uuid}`,
              method: 'PUT',
              data,
            });
            toast.success('Updated!');
            history.push(`/application/afr/${afr_uuid}`);
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            setSubmitting();
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.coapplicants, tags.afrProfile]),
      }),



      // Delete AFR Coapplicant
      deleteAFRCoapplicant: build.mutation({
        queryFn: async ({ afr_uuid, coapplicant_uuid }) => {
          try {
            const response = await axios({
              url: `${baseURL}/afr/coapplicants/${coapplicant_uuid}`,
              method: 'DELETE',
            });
            toast.success('Deleted!');
            history.push(`/application/afr/${afr_uuid}`);
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.coapplicants]),
      }),



      // Get Household Members
      getHousehold: build.query({
        queryFn: async ({ isAFR, afr_uuid }) => {
          let url = `${baseURL}/household-members`;
          if (isAFR) url = `${baseURL}/afr/household-members/afr-id/${afr_uuid}`;
          try {
            const response = await axios({
              url,
              method: 'GET',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        providesTags: [tags.householdMembers],
      }),



      // startAddHousehold
      addHousehold: build.mutation({
        queryFn: async ({ data, setSubmitting }) => {
          try {
            const response = await axios({
              url: `${baseURL}/household-members`,
              method: 'POST',
              data,
            });
            toast.success('Created!');
            history.push('/application');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            setSubmitting();
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.householdMembers, tags.profile]),
      }),



      // startUpdateHousehold
      updateHousehold: build.mutation({
        queryFn: async ({ household_uuid, data, setSubmitting }) => {
          try {
            const response = await axios({
              url: `${baseURL}/household-members/${household_uuid}`,
              method: 'PUT',
              data,
            });
            toast.success('Updated!');
            history.push('/application');
            return { data: response.data };
          } catch (error) {
            setSubmitting();
            console.error(error.response);
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.householdMembers]),
      }),



      // startDeleteHousehold
      deleteHousehold: build.mutation({
        queryFn: async ({ household_uuid, toggle }) => {
          try {
            const response = await axios({
              url: `${baseURL}/household-members/${household_uuid}`,
              method: 'DELETE',
            });
            toggle();
            toast.success('Deleted!');
            history.push('/application'); // ! Needs Conditional Logic, depending on page
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.householdMembers]),
      }),


      // startAddAFRHousehold
      addAFRHousehold: build.mutation({
        queryFn: async ({ afr_uuid, data, setSubmitting }) => {
          try {
            const response = await axios({
              url: `${baseURL}/afr/household-members/${afr_uuid}`,
              method: 'POST',
              data,
            });
            toast.success('Created!');
            history.push(`/application/afr/${afr_uuid}`);
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            setSubmitting();
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.householdMembers, tags.afrProfile]),
      }),



      // startUpdateAFRHousehold
      updateAFRHousehold: build.mutation({
        queryFn: async ({ afr_uuid, household_uuid, data, setSubmitting }) => {
          try {
            const response = await axios({
              url: `${baseURL}/afr/household-members/${household_uuid}`,
              method: 'PUT',
              data,
            });
            toast.success('Updated!');
            history.push(`/application/afr/${afr_uuid}`);
            return { data: response.data };
          } catch (error) {
            setSubmitting();
            console.error(error.response);
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.householdMembers]),
      }),



      // startDeleteAFRHousehold
      deleteAFRHousehold: build.mutation({
        queryFn: async ({ afr_uuid, household_uuid, toggle }) => {
          try {
            const response = await axios({
              url: `${baseURL}/afr/household-members/${household_uuid}`,
              method: 'DELETE',
            });
            toggle();
            toast.success('Deleted!');
            history.push(`/application/afr/${afr_uuid}`); // ! Needs conditional logic based on page
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.householdMembers]),
      }),



      getLoans: build.query({
        queryFn: async ({ isAFR, afr_uuid }) => {
          let url = `${baseURL}/loans`;
          if (isAFR) url = `${baseURL}/afr/loans/afr-id/${afr_uuid}`;
          try {
            const response = await axios({
              url,
              method: 'GET',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        providesTags: [tags.loans],
      }),



      getLoan: build.query({
        queryFn: async ({ isAFR, loan_uuid }) => {
          let url = `${baseURL}/loans/${loan_uuid}`;
          if (isAFR) url = `${baseURL}/afr/loans/${loan_uuid}`;
          try {
            const response = await axios({
              url,
              method: 'GET',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        providesTags: [tags.loans],
      }),



      addLoan: build.mutation({
        queryFn: async ({ data, setSubmitting }) => {
          try {
            const response = await axios({
              url: `${baseURL}/loans`,
              method: 'POST',
              data,
            });
            toast.success('Created!');
            history.push('/application');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            setSubmitting();
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.loans, tags.profile]),
      }),



      updateLoan: build.mutation({
        queryFn: async (payload) => {
          const { loan_uuid, data, setSubmitting } = payload;
          try {
            const response = await axios({
              url: `${baseURL}/loans/${loan_uuid}`,
              method: 'PUT',
              data,
            });
            toast.success('Updated!');
            history.push('/application');
            return { data: response.data };
          } catch (error) {
            setSubmitting();
            console.error(error.response);
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.loans]),
      }),



      deleteLoan: build.mutation({
        queryFn: async ({ loan_uuid }) => {
          try {
            const response = await axios({
              url: `${baseURL}/loans/${loan_uuid}`,
              method: 'DELETE',
            });
            toast.success('Deleted!');
            history.push('/application');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.loans]),
      }),



      addAFRLoan: build.mutation({
        queryFn: async (payload) => {
          const { afr_uuid, data, setSubmitting } = payload;
          try {
            const response = await axios({
              url: `${baseURL}/afr/loans/${afr_uuid}`,
              method: 'POST',
              data,
            });
            toast.success('Created!');
            history.push(`/application/afr/${afr_uuid}`);
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            setSubmitting();
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.loans, tags.afrProfile]),
      }),



      updateAFRLoan: build.mutation({
        queryFn: async (payload) => {
          const { afr_uuid, loan_uuid, data, setSubmitting } = payload;
          try {
            const response = await axios({
              url: `${baseURL}/afr/loans/${loan_uuid}`,
              method: 'PUT',
              data,
            });
            toast.success('Updated!');
            history.push(`/application/afr/${afr_uuid}`);
            return { data: response.data };
          } catch (error) {
            setSubmitting();
            console.error(error.response);
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.loans, tags.afrProfile]),
      }),



      deleteAFRLoan: build.mutation({
        queryFn: async (payload) => {
          const { afr_uuid, loan_uuid } = payload;
          try {
            const response = await axios({
              url: `${baseURL}/afr/loans/${loan_uuid}`,
              method: 'DELETE',
            });
            toast.success('Deleted!');
            history.push(`/application/afr/${afr_uuid}`);
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.loans]),
      }),



      getAssistanceRequests: build.query({
        queryFn: async ({ isAFR, afr_uuid }) => {
          let url = `${baseURL}/assistance-requests`;
          if (isAFR) url = `${baseURL}/afr/assistance-requests/afr-id/${afr_uuid}`;
          try {
            const response = await axios({
              url,
              method: 'GET',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        providesTags: [tags.assistanceRequests],
      }),



      addAssistanceRequest: build.mutation({
        queryFn: async ({ data, setSubmitting }) => {
          try {
            const response = await axios({
              url: `${baseURL}/assistance-requests`,
              method: 'POST',
              data,
            });
            history.push('/application');
            toast.success('Created!');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            setSubmitting();
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.assistanceRequests, tags.profile]),
      }),



      updateAssistanceRequest: build.mutation({
        queryFn: async ({ assistanceRequestID, data, setSubmitting }) => {
          try {
            const response = await axios({
              url: `${baseURL}/assistance-requests/${assistanceRequestID}`,
              method: 'PUT',
              data,
            });
            history.push('/application');
            toast.success('Updated!');
            return { data: response.data };
          } catch (error) {
            setSubmitting();
            console.error(error.response);
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.assistanceRequests, tags.profile]),
      }),



      addAFRAssistanceRequest: build.mutation({
        queryFn: async ({ afr_uuid, data, setSubmitting }) => {
          try {
            const response = await axios({
              url: `${baseURL}/afr/assistance-requests/${afr_uuid}`,
              method: 'POST',
              data,
            });
            history.push(`/application/afr/${afr_uuid}`);
            toast.success('Created!');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            setSubmitting();
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.assistanceRequests, tags.afrProfile]),
      }),



      updateAFRAssistanceRequest: build.mutation({
        queryFn: async ({ assistanceRequestID, data, setSubmitting, afr_uuid }) => {
          try {
            const response = await axios({
              url: `${baseURL}/afr/assistance-requests/${assistanceRequestID}`,
              method: 'PUT',
              data,
            });
            history.push(`/application/afr/${afr_uuid}`);
            toast.success('Updated!');
            return { data: response.data };
          } catch (error) {
            setSubmitting();
            console.error(error.response);
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.assistanceRequests]),
      }),



      getFinancials: build.query({
        queryFn: async ({ isAFR, afr_uuid }) => {
          let url = `${baseURL}/financial-records`;
          if (isAFR) url = `${baseURL}/afr/financial-records/afr-id/${afr_uuid}`;
          try {
            const response = await axios({
              url,
              method: 'GET',
            });
            const { income, expense } = response.data;
            return { data: { incomes: income, expenses: expense } };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        providesTags: [tags.financials],
      }),



      addFinancial: build.mutation({
        queryFn: async ({ data, setSubmitting }) => {
          try {
            const response = await axios({
              url: `${baseURL}/financial-records`,
              method: 'POST',
              data,
            });
            toast.success('Created!');
            history.push('/application');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            setSubmitting();
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.financials, tags.profile]),
      }),



      updateFinancial: build.mutation({
        queryFn: async ({ financialID, data, setSubmitting }) => {
          try {
            const response = await axios({
              url: `${baseURL}/financial-records/${financialID}`,
              method: 'PUT',
              data,
            });
            toast.success('Updated!');
            history.push('/application');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            setSubmitting();
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.financials]),
      }),



      deleteFinancial: build.mutation({
        queryFn: async ({ financialID, cb }) => {
          try {
            const response = await axios({
              url: `${baseURL}/financial-records/${financialID}`,
              method: 'DELETE',
            });
            cb();
            toast.success('Deleted!');
            history.push('/application');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.financials]),
      }),



      addAFRFinancial: build.mutation({
        queryFn: async ({ afr_uuid, data, setSubmitting }) => {
          try {
            const response = await axios({
              url: `${baseURL}/afr/financial-records/${afr_uuid}`,
              method: 'POST',
              data,
            });
            toast.success('Created!');
            history.push(`/application/afr/${afr_uuid}`);
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            setSubmitting();
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.financials, tags.afrProfile]),
      }),



      updateAFRFinancial: build.mutation({
        queryFn: async ({ financialID, data, setSubmitting, afr_uuid }) => {
          try {
            const response = await axios({
              url: `${baseURL}/financial-records/${financialID}`,
              method: 'PUT',
              data,
            });
            toast.success('Updated!');
            history.push(`/application/afr/${afr_uuid}`);
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            setSubmitting();
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.financials]),
      }),



      deleteAFRFinancial: build.mutation({
        queryFn: async ({ financialID, afr_uuid, toggle }) => {
          try {
            const response = await axios({
              url: `${baseURL}/afr/financial-records/${financialID}`,
              method: 'DELETE',
            });
            toggle();
            history.push(`/application/afr/${afr_uuid}`);
            toast.success('Deleted!');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.financials]),
      }),



      getHardship: build.query({
        queryFn: async ({ isAFR, afr_uuid }) => {
          let url = `${baseURL}/hardship`;
          if (isAFR) url = `${baseURL}/afr/hardship/afr-id/${afr_uuid}`;
          try {
            const response = await axios({
              url,
              method: 'GET',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        providesTags: [tags.hardship],
      }),



      addHardship: build.mutation({
        queryFn: async ({ data, setSubmitting }) => {
          try {
            const response = await axios({
              url: `${baseURL}/hardship`,
              method: 'POST',
              data,
            });
            toast.success('Created!');
            history.push('/application');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            setSubmitting();
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.hardship, tags.profile]),
      }),



      updateHardship: build.mutation({
        queryFn: async ({ hardship_uuid, data, setSubmitting }) => {
          try {
            const response = await axios({
              url: `${baseURL}/hardship/${hardship_uuid}`,
              method: 'PUT',
              data,
            });
            toast.success('Updated!');
            history.push('/application');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            setSubmitting();
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.hardship]),
      }),



      addAFRHardship: build.mutation({
        queryFn: async ({ afr_uuid, data, setSubmitting }) => {
          try {
            const response = await axios({
              url: `${baseURL}/afr/hardship/${afr_uuid}`,
              method: 'POST',
              data,
            });
            history.push(`/application/afr/${afr_uuid}`);
            toast.success('Created!');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            setSubmitting();
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.hardship, tags.afrProfile]),
      }),



      updateAFRHardship: build.mutation({
        queryFn: async ({ hardship_uuid, data, setSubmitting, afr_uuid }) => {
          try {
            const response = await axios({
              url: `${baseURL}/afr/hardship/${hardship_uuid}`,
              method: 'PUT',
              data,
            });
            history.push(`/application/afr/${afr_uuid}`);
            toast.success('Updated!');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            setSubmitting();
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.hardship]),
      }),



      getSignatures: build.query({
        queryFn: async () => {
          try {
            const response = await axios({
              url: `${baseURL}/signatures`,
              method: 'GET',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        providesTags: [tags.signatures],
      }),



      generateSignatures: build.mutation({
        queryFn: async ({ data }) => {
          try {
            const response = await axios({
              url: `${baseURL}/signatures`,
              method: 'POST',
              data,
            });
            history.push('/application');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.signatures, tags.profile, tags.afrProfile]),
      }),



      generateManualSignatures: build.mutation({
        queryFn: async () => {
          try {
            const response = await axios({
              url: `${baseURL}/signatures/manual`,
              method: 'POST',
              responseType: 'blob',
            });
            const a = document.createElement('a');
            const [, fileName] = response.headers['content-disposition'].split('filename=');
            a.download = decodeURI(fileName);
            console.log(typeof response.data);
            a.href = window.URL.createObjectURL(new Blob([response.data]));
            document.body.append(a);
            a.click();
            history.push('/application');
            return { data: fileName };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong. Please try again.');
            return { error: error.message };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.signatures, tags.profile]),
      }),



      generateAFRManualSignatures: build.mutation({
        queryFn: async ({ afr_uuid }) => {
          try {
            const response = await axios({
              url: `${baseURL}/afr/signatures/download-manual/${afr_uuid}`,
              method: 'GET',
              responseType: 'blob',
            });
            const a = document.createElement('a');
            const [, fileName] = response.headers['content-disposition'].split('filename=');
            a.download = decodeURI(fileName);
            console.log(typeof response.data);
            a.href = window.URL.createObjectURL(new Blob([response.data]));
            document.body.append(a);
            a.click();
            history.push(`/application/afr/${afr_uuid}`);
            return { data: fileName };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong. Please try again.');
            return { error: error.message };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.signatures, tags.profile, tags.afrProfile]),
      }),



      downloadSignature: build.query({
        queryFn: async ({ external_id }) => {
          try {
            const response = await axios({
              url: `${baseURL}/signatures/download/${external_id}`,
              method: 'GET',
              responseType: 'blob',
            });
            const a = document.createElement('a');
            const [, fileName] = response.headers['content-disposition'].split('filename=');
            a.download = decodeURI(fileName);
            a.href = window.URL.createObjectURL(new Blob([response.data]));
            document.body.append(a);
            a.click();
            return { data: fileName };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong. Please try again.');
            return { error: error.message };
          }
        },
        providesTags: [],
      }),



      requestSignature: build.mutation({
        queryFn: async () => {
          try {
            const response = await axios({
              url: `${baseURL}/request-signatures`,
              method: 'PUT',
            });
            toast.success('E-Sign Requested!');
            history.push('/application');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.signatures]),
      }),



      getAFRSignatures: build.query({
        queryFn: async ({ afr_uuid }) => {
          try {
            const response = await axios({
              url: `${baseURL}/afr/signature/${afr_uuid}`,
              method: 'GET',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error };
          }
        },
        providesTags: [tags.signatures],
      }),



      generateAFRSignatures: build.mutation({
        queryFn: async ({ data, afr_uuid }) => {
          try {
            const response = await axios({
              url: `${baseURL}/afr/signature/${afr_uuid}`,
              method: 'POST',
              data,
            });
            toast.success('Submitted!');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.signatures, tags.profile, tags.afrProfile]),
      }),



      downloadAFRSignature: build.query({
        queryFn: async ({ external_id }) => {
          try {
            const response = await axios({
              url: `${baseURL}/afr/signatures/download/${external_id}`,
              method: 'GET',
              responseType: 'blob',
            });
            const a = document.createElement('a');
            const [, fileName] = response.headers['content-disposition'].split('filename=');
            a.download = decodeURI(fileName);
            a.href = window.URL.createObjectURL(new Blob([response.data]));
            document.body.append(a);
            a.click();
            return { data: fileName };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong. Please try again.');
            return { error: error.message };
          }
        },
        providesTags: [],
      }),



      requestAFRSignature: build.mutation({
        queryFn: async ({ data, afr_uuid }) => {
          try {
            const response = await axios({
              url: `${baseURL}/afr/signature/${afr_uuid}`,
              method: 'POST',
              data,
            });
            toast.success('Submitted!');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.signatures]),
      }),



      getStatus: build.query({
        queryFn: async () => {
          try {
            const response = await axios({
              url: `${baseURL}/status`,
              method: 'GET',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        providesTags: [tags.status],
      }),
    }),
  });



export const {
  useGetProfileQuery,
  useUpdateProfileMutation,
  useLazyGetVerifiedAddressesQuery,
  useLazyGetLWCHomesteadOccQuery,

  useGetAllAFRsQuery,
  useGetAFRProfileQuery,
  useUpdateAFRProfileMutation,
  useStartAdditionalFundsRequestMutation,

  useGetCoapplicantsQuery,
  useGetCoapplicantQuery,
  useAddCoapplicantMutation,
  useUpdateCoapplicantMutation,
  useDeleteCoapplicantMutation,
  useAddAFRCoapplicantMutation,
  useUpdateAFRCoapplicantMutation,
  useDeleteAFRCoapplicantMutation,

  useGetHouseholdQuery,
  useAddHouseholdMutation,
  useUpdateHouseholdMutation,
  useDeleteHouseholdMutation,

  useGetAFRHouseholdQuery,
  useAddAFRHouseholdMutation,
  useUpdateAFRHouseholdMutation,
  useDeleteAFRHouseholdMutation,

  useGetLoansQuery,
  useGetLoanQuery,
  useAddLoanMutation,
  useUpdateLoanMutation,
  useDeleteLoanMutation,
  useAddAFRLoanMutation,
  useUpdateAFRLoanMutation,
  useDeleteAFRLoanMutation,

  useGetAssistanceRequestsQuery,
  useAddAssistanceRequestMutation,
  useUpdateAssistanceRequestMutation,
  useAddAFRAssistanceRequestMutation,
  useUpdateAFRAssistanceRequestMutation,

  useGetFinancialsQuery,
  useAddFinancialMutation,
  useUpdateFinancialMutation,
  useDeleteFinancialMutation,
  useAddAFRFinancialMutation,
  useUpdateAFRFinancialMutation,
  useDeleteAFRFinancialMutation,

  useGetHardshipQuery,
  useAddHardshipMutation,
  useUpdateHardshipMutation,
  useAddAFRHardshipMutation,
  useUpdateAFRHardshipMutation,

  useGetSignaturesQuery,
  useGenerateSignaturesMutation,
  useGenerateManualSignaturesMutation,
  useGenerateAFRManualSignaturesMutation,
  useLazyDownloadSignatureQuery,
  useRequestSignatureMutation,
  useGetAFRSignaturesQuery,
  useGenerateAFRSignaturesMutation,
  useLazyDownloadAFRSignatureQuery,
  useRequestAFRSignatureMutation,

  useGetStatusQuery,
} = applicationApi;
