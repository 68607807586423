import React from 'react';
import PropTypes from 'prop-types';

const LandingPageBanner = ({ message }) => (
  <div className='landing-page-banner fixed-top text-truncate'>
    {message}
  </div>
);

LandingPageBanner.propTypes = {
  message: PropTypes.string,
};

export default LandingPageBanner;
