import React from 'react';

const config = stateConfig;

// CREATE OPTIONS
export const createOptions = (arr = []) => arr.map((d, i) => <option key={i} value={d} className='text-capitalize'>{d}</option>);


// IS SECTION READY
export const isSectionReady = ({
  feature_coapplicant_ready,
  feature_household_ready,
  feature_loan_ready,
  feature_assistance_ready,
  feature_financial_ready,
  feature_hardship_ready,
  feature_documents_ready,
  feature_signatures_ready,
}) => {
  const isCoApplicantReady = feature_coapplicant_ready;
  const isHouseholdReady = feature_household_ready;
  const isLoansReady = feature_loan_ready;
  const isAssistanceReady = feature_assistance_ready;
  const isFinancialsReady = feature_financial_ready;
  const isHardshipReady = feature_hardship_ready;
  const isDocumentsReady = feature_documents_ready;
  const isSignatureReady = feature_signatures_ready;

  return {
    isCoApplicantReady,
    isHouseholdReady,
    isLoansReady,
    isAssistanceReady,
    isFinancialsReady,
    isHardshipReady,
    isDocumentsReady,
    isSignatureReady,
  };
};

// GET FEATURE FLAGS FROM CONFIG
export const getFeatureFlagsArray = (configApplications) => {
  const featureFlags = [];

  if (!configApplications || Object.keys(configApplications) === 0) {
    return null;
  }

  // CHECK FLAGS
  // -- ADMIN
  if (configApplications.statusSection?.isVisible) featureFlags.push('status');
  if (configApplications.communicationSection?.isVisible) featureFlags.push('communications');
  if (configApplications.checklistSection?.isVisible) featureFlags.push('checklists');
  if (configApplications.recordSection?.isVisible) featureFlags.push('records');
  if (configApplications.letterSection?.isVisible) featureFlags.push('letters');
  if (configApplications.recertificationSection?.isVisible) featureFlags.push('recertification');
  if (configApplications.prescreenSection?.isVisible) featureFlags.push('prescreen');
  // -- CLIENT
  if (configApplications.applicantSection?.isVisible) featureFlags.push('applicant');
  if (configApplications.coapplicantSection?.isVisible) featureFlags.push('co-applicant');
  if (configApplications.householdSection?.isVisible) featureFlags.push('household');
  if (configApplications.loanSection?.isVisible) featureFlags.push('loan');
  if (configApplications.assistanceSection?.isVisible) featureFlags.push('assistance');
  if (configApplications.financialSection?.isVisible) featureFlags.push('financial');
  if (configApplications.hardshipSection?.isVisible) featureFlags.push('hardship');
  if (configApplications.documentSection?.isVisible) featureFlags.push('documents');
  if (configApplications.signatureSection?.isVisible) featureFlags.push('signatures');
  return featureFlags;
};

// CONVERT HEXCODES - '#FFFFFF' to (255, 255, 255,)
export const hexToRGB = (hex) => {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `${r}, ${g}, ${b}`;
};
