import { toast } from 'react-toastify';

import { apiSlice } from '../apiSlice';
import axios from '../../utils/axios';

export const tags = {
  messages: 'messages',
};

const messagesApi = apiSlice
  .enhanceEndpoints({ addTagTypes: Object.values(tags) })
  .injectEndpoints({
    endpoints: (build) => ({

      getMessages: build.query({
        queryFn: async (_, api) => {
          // Fixes polling issue where one more request is sent out after logging out
          if (!api.getState().auth.isLoggedIn) return { data: [] };
          try {
            const response = await axios({
              url: `${baseURL}/communications`,
              method: 'GET',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        providesTags: [tags.messages],
      }),

      sendMessages: build.mutation({
        queryFn: async (payload) => {
          const { data, setSubmitting, cb } = payload;
          console.debug('queryFn: ༻༺ payload:', payload);
          try {
            const response = await axios({
              url: `${baseURL}/communications`,
              method: 'POST',
              data,
            });
            toast.success('Sent!');
            cb();
            setSubmitting();
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            setSubmitting();
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [...Object.values(tags)]),
      }),

      sendReadReceipts: build.mutation({
        queryFn: async (payload) => {
          try {
            const response = await axios({
              url: `${baseURL}/communications/read`,
              method: 'POST',
              data: payload,
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [...Object.values(tags)]),
      }),
    }),
  });

export const {
  useGetMessagesQuery,
  useSendMessagesMutation,
  useSendReadReceiptsMutation,
} = messagesApi;
